import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {createTheme, ThemeProvider} from "@mui/material";
import HomePage from "./components/HomePage/HomePage.component";
import Login from "./components/Login/Login.component";
import Orders from "./components/Orders/Orders.component";
import Scanner from "./components/Scanner/Scanner.component";
import Register from "./components/Register/Register.component";
import Layout from "./components/Layout/Layout.component";

const theme = createTheme({
    palette: {
        primary: {
            main: '#000',
        }
    }
});

const root = createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route path="orders" element={<Orders />} />
                        <Route path="scanner" element={<Scanner />} />
                        <Route path="register" element={<Register />} />
                        <Route path="login" element={<Login />} />
                        <Route path="/" element={<HomePage />} />
                    </Route>
                </Routes>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>
);
