import React, {useEffect, useState} from "react";
import { useLocation } from 'react-router-dom';
import QrScanner from 'qr-scanner';
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {Alert, Backdrop, Box, Container, Typography} from "@mui/material";
import axios from "../../api/axios";

const Scanner = () => {

    const location= useLocation();
    const pathname = location.pathname;

    const [successScan, setSuccessScan] = useState(false)
    const [openBackDrop, setOpenBackDrop] = useState(false)
    const [itemData, setItemData] = useState({})
    const [showAlert, setShowAlert] = useState(false);

    let qrScanner;
    let qrScannerStopped = true;

    useEffect(() => {
        return  () => {
            if (!qrScannerStopped) {
                try {
                    qrScanner.destroy();
                    qrScanner = null;
                    qrScannerStopped = true;
                } catch (e) {
                    console.log(e)
                }
            }
        }
    }, [])

    const onSuccess = (decodedText) => {
        let url = JSON.parse(decodedText).url;
        getItemData(url);
        setSuccessScan(true)
        qrScanner.stop();
    }

    const getItemData = (url) => {
        axios.get(url,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        ).then((response) => {
            setItemData(response.data)
        })
    }

    const redeem = () => {
        setOpenBackDrop(true)

        axios.put(itemData.url,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        ).then((response) => {
            setShowAlert(true)
            setOpenBackDrop(false)
            setSuccessScan(false)
            qrScanner.start();
        }).catch((error) => {
            if (error) {
                setShowAlert(false)
                setOpenBackDrop(false)
                setSuccessScan(false)
            }

            if (error && error.response && error.response.status === 400) alert("Ticket bereits eingelöst")
            if (error && error.response && error.response.status === 403) alert("Ticket ist nicht mehr gültig")
            if (error && error.response && error.response.status === 404) alert("Auftrag oder Artikel nicht gefunden")
        });
    }

    const startScanner = () => {
        qrScannerStopped = false;

        qrScanner = new QrScanner(
            document.getElementById("scanner"),
            result => onSuccess(result.data),
            {
                scanRegion: {
                    x: 20,
                    y: 20,
                    width: 300,
                    height: 350
                },
                maxScansPerSecond: 3,
                highlightScanRegion: true
            }
        );
        if (!successScan) qrScanner.start().then().catch(() => {startScanner()});
    }

    const checkLoginStatus = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/login/status/`,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        ).finally(() => {});
    }

    useEffect(() => {
        if (!qrScanner && !successScan && pathname === "/scanner") {
            startScanner();
        }
    }, [successScan]);

    useEffect(() => {
        checkLoginStatus();
    }, []);

    return <>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackDrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        {showAlert ? <Alert
            onClose={() => {
                setShowAlert(false)
            }}
            sx={{position: 'fixed', width: '300px', right: '15px', top: '100px'}}
            severity="success">
            Artikel eingelöst
        </Alert> : ''}
        <Container maxWidth={"lg"}>
            <div>
                <h1>Scanner</h1>
            </div>
            <Box component="div" sx={{width: '100%', justifyContent: 'center', display: {xs: "block", sm: "flex"}}}>
                {!successScan ? <>
                    <Box sx={{display: "flex", justifyContent: "center"}}>
                        <video id={"scanner"} style={{border: "1px solid #ddd", minWidth: 320, maxWidth: 350, width: '100%', minHeight: 320, maxHeight: 3000, height: '100%'}}></video>
                    </Box>
                    </> : <>
                    { itemData["item"] && !itemData["item"].redeemed ? <div>
                        <Typography variant={"h5"} gutterBottom>
                            {itemData["item"].name}
                        </Typography>
                        <Typography gutterBottom>
                            {itemData["item"].value}
                        </Typography>
                        <Typography gutterBottom>
                            {itemData["item"].competition}
                        </Typography>
                        <Typography gutterBottom>
                            {itemData["item"].matchDate} | {itemData["item"].matchTime}
                        </Typography>
                        <Button sx={{mt: 2, minWidth: 250, display: 'block', width: { xs: '100%', sm: 'auto'}}} size={"large"} variant={"contained"} color={"success"} onClick={() => redeem()}>Bestätigen</Button>
                        <br/>
                        <Button sx={{minWidth: 250, display: 'block', width: { xs: '100%', sm: 'auto'}}} size={"large"} variant={"outlined"} onClick={() => {setSuccessScan(false)}}>
                            Zurück zum Scanner
                        </Button>
                    </div> : <div style={{textAlign:'center'}}>
                        <div style={{color:'#fff',backgroundColor:'red',padding:'2rem'}}>
                            <span style={{display:'block',marginBottom:'1rem',fontSize:'1.5rem',textAlign:'center'}}>
                                <HighlightOffIcon sx={{height:'4rem',width:'4rem'}} />
                            </span>
                            Bereits eingelöst
                        </div><br/>
                        <Button sx={{minWidth: 250, display: 'block', width: { xs: '100%', sm: 'auto'}}} size={"large"} variant={"outlined"} onClick={() => {setSuccessScan(false)}}>
                            Zurück zum Scanner
                        </Button>
                    </div> }
                </> }
            </Box>
        </Container>

    </>
}

export default Scanner;
