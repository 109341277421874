import {
    Avatar,
    Box,
    Container,
    TextField,
    Typography,
    Alert
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import Button from "@mui/material/Button";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useNavigate} from "react-router-dom";
import axios from "../../api/axios";

const Login = () => {

    const navigate = useNavigate("/");

    const userRef = useRef();
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [emailErr, setEmailErr] = useState(false);
    const [pwdErr, setPwdErr] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = (e) => {
        e.preventDefault();

        try {
            if (!user || !pwd) {
                setEmailErr(!user)
                setPwdErr(!pwd)
                return;
            }

            if (user.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/) == null) {
                setEmailErr(true)
                return;
            }

            axios.post(`${process.env.REACT_APP_BASE_URL}/login`,
                JSON.stringify({ "email": user, "password": pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            ).then(response => {
                if (response.status === 200) navigate("/");
            });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('E-Mail und oder Passwort nicht korrekt');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            setShowAlert(true);
        }
    }

    return (
        <>
            {showAlert ? <Alert
                onClose={() => {
                    setShowAlert(false)
                }}
                sx={{position: 'fixed', width: '300px', right: '15px', top: '100px'}}
                severity="error">
                {errMsg}
            </Alert> : ''}
            <Container maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'black' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Anmelden
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            ref={userRef}
                            value={user}
                            required
                            fullWidth
                            id="email"
                            label="E-Mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            validate={"email"}
                            error={emailErr}
                            helperText={emailErr ? "Gültige E-mail angeben" : ''}
                            onChange={(e) => {
                                setUser(e.target.value)
                                setEmailErr(false)
                            }}
                        />
                        <TextField
                            value={pwd}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Passwort"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            error={pwdErr}
                            helperText={pwdErr ? "Bitte Passwort eingeben" : ''}
                            onChange={(e) => {
                                setPwd(e.target.value)
                                setPwdErr(false)
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            size={"large"}
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Anmelden
                        </Button>
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default Login;
