import { Outlet } from "react-router-dom"
import MenuBar from "../Menu/MenuBar.component";
import Footer from "../Footer/Footer.component";

const Layout = () => {
    return (
        <>
            <MenuBar />
            <main className="App" style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '95vh',
            }} >
                <Outlet />
                <Footer />
            </main>
        </>
    )
}

export default Layout
