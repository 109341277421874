import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary">
            {'Copyright © '}
            {new Date().getFullYear()}
        </Typography>
    );
}
const StickyFooter = () => {
    return (
        <Box
            component="footer"
            sx={{
                py: 3,
                px: 2,
                mt: 'auto'
            }}
        >
            <Container maxWidth="sm" sx={{textAlign: 'center'}}>
                <Typography variant="body1">
                    BFC
                </Typography>
                <Copyright />
            </Container>
        </Box>
    );
}

export default StickyFooter;
