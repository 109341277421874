const HomePage = () => {

    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "50px 15px"}}>
            <div>
                <h1 style={{textAlign: "center"}}>BFC Ticket <br/>&Uuml;bersicht</h1>
            </div>
        </div>
    )
}

export default HomePage;
