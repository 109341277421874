import React, {useEffect, useState} from "react";
import {
    Box,
    Grid,
    Table,
    TableBody,
    Paper,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Modal,
    Card,
    CardContent,
    Pagination,
    IconButton,
    Alert,
    Container,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Button
} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "../../api/axios";
import { ArrowDropDownCircleRounded, Download } from "@mui/icons-material";
import { mkConfig, generateCsv, download } from "export-to-csv";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [order, setOrder] = useState({});
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(3);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [season, setSeason] = useState("2024/2025");
    const [matchDay, setMatchDay] = useState("1");

    useEffect(() => {
        checkLoginStatus();
    }, []);

    const downloadItems = () => {
        setOpen(true);

        const csvConfig = mkConfig({
            useKeysAsHeaders: true,
            filename: `${matchDay}-${season}`
        });

        axios.get(`${process.env.REACT_APP_BASE_URL}/order/match/items?matchDay=${matchDay}&season=${season}`,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        ).then((response) => {

            if (response.data && response.data.length > 0) {
                let data = prepareData(response.data);
                const csv = generateCsv(csvConfig)(data);
                download(csvConfig)(csv);
            } else {
                setAlertMsg("Keine Tickets zu diesen Daten gefunden.");
                setAlertType("warning");
                setShowAlert(true);
            }
            setOpen(false);
        }).catch(() => {
            setAlertMsg("Da ist etwas schief gelaufen. Bitte versuche es erneut.");
            setAlertType("error");
            setShowAlert(true);
            setOpen(false);
        });
    }

    const prepareData = (data) => {
        let arrData = [];

        for (let item of data) {
            arrData.push({
                "Auftrag": item.order.externalId,
                "Kunde": item.order.customer.firstName + " " + item.order.customer.lastName,
                "Email": item.order.customer.email,
                "Spiel": item.competition,
                "Wert": item.value,
                "Spieltag": item.matchDay,
                "Saison": item.season,
                "Datum": item.matchDate,
            })
        }

        return arrData;
    }

    const getOrders = (page) => {
        setOpen(true);

        axios.get(`${process.env.REACT_APP_BASE_URL}/orders/list?cols[]=customer&page=` + page,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        ).then((response) => {
            setOrders(response.data.orders);
            setTotalPages(response.data.pages);
            setPage(response.data.page);
            setOpen(false);
        }).catch(() => {
            setOpen(false);
        });
    }

    const sendOrderMail = (event, id) => {
        event.preventDefault();
        event.stopPropagation();

        setOpen(true);

        axios.get(`${process.env.REACT_APP_BASE_URL}/orders/order/mail/${id}`,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        ).then((response) => {
            setAlertMsg("E-Mail wurde erfolgreich versendet.");
            setAlertType("success");
            setShowAlert(true)
            setOpen(false);
        }).catch(() => {
            setOpen(false);
            setAlertMsg("E-Mail wurde erfolgreich versendet.");
            setAlertType("success");
            setShowAlert(true)
        });
    }

    const checkLoginStatus = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/login/status/`,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        ).finally(() => {});
    }


    const getOrder = (id) => {
        setOpen(true);

        axios.get(`${process.env.REACT_APP_BASE_URL}/orders/order/` + id,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        ).then((response) => {
            setOrder(response.data)
            setSelectedOrderId(id);
            setOpen(false);
            setOpenModal(true);
        }).catch(() => {
            setOpen(false);
        });
    }

    const style = {
        height: 'calc(100% - 30px)',
        overflowY: 'auto',
        bgcolor: '#f7f7f7',
        padding: '15px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'calc(100% - 30px)',
    };

    const ModalOrderDetails = () => {
        return <Modal
            open={openModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} >
                <div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <h2>Auftrag {order.externalId}</h2>
                        <IconButton onClick={() => setOpenModal(false)} size="large"><CloseIcon fontSize={"inherit"}/></IconButton>
                    </div>
                </div>
                <div style={{marginBottom: 30, marginTop: 10}}>
                    <Card>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Kunde
                            </Typography>
                            <Typography variant="h5" component="div">
                                {order.customer?.firstName} {order.customer?.lastName}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }}>
                                {order.customer?.email}
                            </Typography>
                            <Typography variant="body2">
                                Externe-Id: {order.customer?.externalCustomerId}
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
                {order.items?.map(item => {
                    return <div key={item.id} style={{marginBottom: 30}}>
                        <Card>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <Box sx={{display: "inline-block", mb: 2}}>
                                            <Typography variant="h5" component="div">
                                                {item.name}
                                            </Typography>
                                            <Typography>
                                                {item.value}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={2}>
                                        <Box sx={{display: "inline-block", mb: 2}}>
                                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                                Wettbewerb
                                            </Typography>
                                            <Typography>
                                                {item.competition}
                                            </Typography>
                                            <Typography>
                                                {item.matchDay} {item.season}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <Box sx={{display: "inline-block", mb: 2}}>
                                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                                Ort / Stadium
                                            </Typography>
                                            <Typography variant="h5" component="div">
                                                {item.stadium}
                                            </Typography>
                                            <Typography>
                                                {item.address}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} md={3} lg={2}>
                                        <Box sx={{display: "inline-block", mb: 2}}>
                                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                                Datum
                                            </Typography>
                                            <Typography
                                                        gutterBottom>
                                                {item.matchDate ? new Date(item.matchDate).toLocaleDateString() + " | " + item.matchTime : 'Nicht vorhanden'}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={2}>
                                        <Box sx={{display: "inline-block", mb: 2}}>
                                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                                Eingelöst
                                            </Typography>
                                            <Typography variant="h5" component="div">
                                                {item.redeemed ? <CheckCircleIcon fontSize={"large"} color={"success"} /> :
                                                <HighlightOffIcon fontSize={"large"} color={"error"} />}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </div>
                })}
            </Box>
        </Modal>
    }

    return (
        <>
            {showAlert ? <Alert
                onClose={() => {
                    setShowAlert(false)
                }}
                sx={{position: 'fixed', width: '300px', right: '15px', top: '100px'}}
                severity={alertType}>
                {alertMsg}
            </Alert> : ''}
            <Container maxWidth={"lg"} className="dashboard" style={{padding: 20, overflowX: 'hidden'}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div style={{marginTop: "1rem", display: "flex", gap: 10, alignItems: "center"}}>
                    <h1>Aufträge</h1>
                    <IconButton size={"lg"} onClick={() => {getOrders(1)}}>
                        <RefreshIcon fontSize={"inherit"}/>
                    </IconButton>
                </div>
                <div className="downloadItems" style={{margin: "15px 0 20px 0", backgroundColor: "#f7f7f7"}}>
                    <Accordion style={{backgroundColor: "#f7f7f7"}}>
                        <AccordionSummary
                        expandIcon={<ArrowDropDownCircleRounded />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                        >
                        <Typography>Ticket Download</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{display: "flex", columnGap: "15px", alignItems: "center"}}>
                                <div>
                                    <FormControl>
                                        <InputLabel id="match-day-label">Spieltag</InputLabel>
                                        <Select 
                                            value={matchDay} 
                                            onChange={(event) => {setMatchDay(event.target.value)}} 
                                            labelId="match-day-label"
                                            label="Spieltag">
                                                {new Array(34).fill(1).map((_, index) => {
                                                    return <MenuItem key={index + "sp"} value={index+1}>{index + 1}. Spieltag</MenuItem>
                                                })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div>
                                    <FormControl>
                                        <InputLabel id="season-label">Saison</InputLabel>
                                        <Select 
                                            value={season} 
                                            onChange={(event) => {setSeason(event.target.value)}} 
                                            labelId="season-label"
                                            label="Saison">
                                            <MenuItem value={"2023/2024"}>2023/2024</MenuItem>
                                            <MenuItem value={"2024/2025"}>2024/2025</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{height: "100%"}}>
                                    <Button download id="csv" style={{height: "100%", padding: "13px"}} variant="outlined" onClick={() => downloadItems()} size="large" endIcon={<Download fontSize={"inherit"}/>}>Download</Button>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                {orders.length > 0 ?
                    <>
                        { totalPages > 1 ?
                            <div style={{display: "flex", margin: "1rem 0 1rem 0"}}>
                                <div style={{marginLeft: 'auto'}}>
                                    <Pagination count={totalPages} defaultPage={page} siblingCount={0} onChange={(event, page) => {
                                        getOrders(page);
                                    }}/>
                                </div>
                            </div> : <></>
                        }
                        <div>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow key={"orderItem-header"}>
                                            <TableCell>Id</TableCell>
                                            <TableCell>Käufer</TableCell>
                                            <TableCell>Kaufer - E-Mail</TableCell>
                                            <TableCell>Anzahl Artikel</TableCell>
                                            <TableCell>Bezahlt</TableCell>
                                            <TableCell>E-Mail</TableCell>
                                            <TableCell>Storniert</TableCell>
                                            <TableCell>Erstellt am</TableCell>
                                            <TableCell>E-Mail senden</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orders.map(orderItem => {
                                            return <>
                                                <TableRow
                                                    onClick={() => {getOrder(orderItem.id)}}
                                                    key={orderItem.id}
                                                    selected={selectedOrderId === orderItem.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {orderItem.externalId}
                                                    </TableCell>
                                                    <TableCell>
                                                        {orderItem.customer.firstName} {orderItem.customer.lastName}
                                                    </TableCell>
                                                    <TableCell>
                                                        {orderItem.customer.email}
                                                    </TableCell>
                                                    <TableCell>
                                                        {orderItem.itemCount}
                                                    </TableCell>
                                                    <TableCell >
                                                        {orderItem.paid ? <CheckCircleIcon color={"success"}/> : 'Nein'}
                                                    </TableCell>
                                                    <TableCell>{orderItem.mailStatus ? <CheckCircleIcon color={"success"}/> : <HighlightOffIcon color={"error"}/>}</TableCell>
                                                    <TableCell>{orderItem.canceled ? <CheckCircleIcon color={"error"}/> : '-'}</TableCell>
                                                    <TableCell>{new Date(orderItem.createdAt).toLocaleDateString()}</TableCell>
                                                    <TableCell>
                                                        <IconButton aria-label="Send email" onClick={(event) => {sendOrderMail(event, orderItem.id)}}>
                                                            <EmailIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        { totalPages > 1 ?
                            <div style={{display: "flex", margin: "1rem 0 1rem 0"}}>
                                <div style={{marginLeft: 'auto'}}>
                                    <Pagination count={totalPages} defaultPage={page} siblingCount={0} onChange={(event, page) => {
                                        getOrders(page);
                                    }}/>
                                </div>
                            </div> : <></>
                        }
                    </> : '' }
                <ModalOrderDetails></ModalOrderDetails>
            </Container>
        </>
    );
}

export default Orders;
