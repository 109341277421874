import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import BFC from '../../assets/bfc_logo_d.png';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import {useState} from "react";
import {NavLink} from "react-router-dom";

const pages = [{"name": 'Aufträge', "route": "/orders"}, {"name": 'Scanner', "route": "/scanner"}];

const MenuBar = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static" color={"default"}>
            <Container maxWidth="lg">
                <Toolbar disableGutters style={{justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{marginRight: 15, padding: "5px 0"}}>
                        <img src={BFC} alt="BFC" style={{width: "60px"}} />
                    </div>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <MenuItem key={"scanner"}>
                                <NavLink
                                    style={{
                                        padding: ".25rem .5rem", 
                                        textDecoration: "none", 
                                        color: "#000", 
                                        fontWeight: "600",
                                    }}
                                    key={"scanner-link"}
                                    to={"/scanner"}
                                    className={({ isActive, isPending }) =>
                                        isPending ? "pending" : isActive ? "active" : ""
                                    }
                                >
                                    Scanner
                                </NavLink>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', alignItems: "center" } }}>
                        {pages.map((page) => (
                            <NavLink
                                style={{
                                    padding: ".25rem .5rem", 
                                    textDecoration: "none", 
                                    color: "#000", 
                                    fontWeight: "600",
                                }}
                                key={page.name}
                                to={page.route}
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                            >
                                {page.name}
                            </NavLink>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default MenuBar;
