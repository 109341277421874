import axios, {AxiosError} from 'axios';

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    headers: {
        'Content-type': 'application/json',
    },
});

axiosClient.interceptors.response.use(
    response => {
        return response;
    },
    (error: AxiosError) => {
        if (error.response && error.response.status === 401) {
            if (window.location.pathname !== '/login')
                window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);
export default axiosClient;
